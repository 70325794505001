<template>
  <div class="h-screen flex flex-col justify-between items-center">

    <Menu />

    <!-- MAIN LOGO PROPOSALS -->
    <div class="proposals animate-fade-up animate-ease-in-out h-4/5 mx-auto px-5 lg:px-10 xl:px-16 flex flex-col items-center text-primary">
      <div class="w-fit mt-5 flex flex-col justify-center items-center">
        <h1 class="heading mt-20 mb-12 font-bold text-3xl lg:text-4xl xl:text-5xl">
          Logo Proposals for <span class="text-secondary font-bold">{{ businessName }}</span>
        </h1>
      </div>
      <div class="p-2.5 grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-6">
        <div v-for="(image, index) in filteredImages"
          class="glass m-2.5 border border-solid border-neutral-300 rounded-md cursor-pointer shadow-sm hover:bg-neutral-300"
          :key="index"
          @click="selectImage(image)">
          <img class="w-[200px] h-auto" :src="image.src" :alt="image.industry" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      businessName: "",
      industry: "",
      style: "",
      tone: "",
      images: [],
      selectedImage: null,
    };
  },
  created() {
    this.businessName = this.$store.state.businessName;
    this.industry = this.$store.state.industry;
    this.style = this.$store.state.style;
    this.tone = this.$store.state.tone;
    this.images = this.$store.state.images;
  },
  computed: {
    ...mapGetters(["filteredImages"]),
  },
  methods: {
    selectImage(image) {
      this.selectedImage = image.src;
      this.$store.commit("setSelectedImage", this.selectedImage);
      this.$router.push("/modify");
    },
  },
};
</script>
