<template>
    <footer class="bg-[#0B1A2F] w-full flex items-center justify-center">
        <div class="w-full px-2 text-white">          
          <div class="flex flex-col lg:flex-row justify-between m-8 gap-y-8">
            <div class="logo">
              <div class="h-[30px] flex justify-center lg:justify-start items-centerz-20">
                <router-link to="/">
                  <img class="w-[100px]" src="../assets/LogoYa-logo.svg" alt="logo" />
                </router-link>
              </div>     
            </div>
            <div class="flex flex-col md:flex-row space-y-4 lg:space-y-0 lg:space-x-8 items-center lg:items-start justify-around">
              <a class="cursor-pointer text-white hover:scale-110 hover:text-secondary transition-transform duration-200">Privacy policy</a>
              <a class="cursor-pointer text-white hover:scale-110 hover:text-secondary transition-transform duration-200">Our tools</a>
              <a class="cursor-pointer text-white hover:scale-110 hover:text-secondary transition-transform duration-200">Our plans</a>
              <a class="cursor-pointer text-white hover:scale-110 hover:text-secondary transition-transform duration-200">LogoYa Blog</a>
            </div>
          </div>
          <hr class="border-white m-8"/>
          <div class="flex flex-col-reverse lg:flex-row justify-between m-8 gap-y-8">
            <!-- Copyright on Mobile (Will move to the bottom) -->
            <div class="copyright">
              <p class="w-full text-center">© 2024 Logo<span class="text-secondary">Ya</span>. All rights reserved. | <a href="mailto:contact@logoya.app">contact@logoya.app</a></p>
            </div>
            <div class="flex justify-between lg:space-x-8">
              <a href="https://pl.pinterest.com/" aria-label="Pinterest" class="hover:scale-110">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"  class="hover:stroke-secondary transition-colors duration-300">
                  <g fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" color="white">
                    <path d="M12 11L8 21m1.974-4.428A5 5 0 1 0 7.67 14.5"/><circle cx="12" cy="12" r="10"/>
                  </g>
                </svg>
              </a>
              <a href="https://x.com/" aria-label="Twitter (X)" class="hover:scale-110">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16">
                  <path fill="white" d="M9.294 6.928L14.357 1h-1.2L8.762 6.147L5.25 1H1.2l5.31 7.784L1.2 15h1.2l4.642-5.436L10.751 15h4.05zM7.651 8.852l-.538-.775L2.832 1.91h1.843l3.454 4.977l.538.775l4.491 6.47h-1.843z"/>
                </svg>    
              </a>
              <a href="https://www.youtube.com/channel/" aria-label="YouTube" class="hover:scale-110">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g fill="white" fill-rule="evenodd" clip-rule="evenodd">
                    <path d="M10.386 8.357A.75.75 0 0 0 9.25 9v6a.75.75 0 0 0 1.136.643l5-3a.75.75 0 0 0 0-1.286zM13.542 12l-2.792 1.675v-3.35z"/>
                    <path d="M17.03 4.641a64.5 64.5 0 0 0-10.06 0l-2.241.176a2.975 2.975 0 0 0-2.703 2.475a28.6 28.6 0 0 0 0 9.416a2.975 2.975 0 0 0 2.703 2.475l2.24.176c3.349.262 6.713.262 10.062 0l2.24-.176a2.975 2.975 0 0 0 2.703-2.475c.52-3.117.52-6.299 0-9.416a2.975 2.975 0 0 0-2.703-2.475zM7.087 6.137a63 63 0 0 1 9.828 0l2.24.175c.676.053 1.229.56 1.34 1.228a27 27 0 0 1 0 8.92a1.475 1.475 0 0 1-1.34 1.228l-2.24.175a63 63 0 0 1-9.828 0l-2.24-.175a1.475 1.475 0 0 1-1.34-1.228a27 27 0 0 1 0-8.92a1.475 1.475 0 0 1 1.34-1.228z"/>
                  </g>
                </svg>                            
              </a>
              <a href="https://www.instagram.com/" aria-label="Instagram" class="hover:scale-110">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path fill="white" d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3"/>
                </svg>                            
              </a>
            </div>
          </div>
        </div>
      </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
