<template lang="">
  <div>

    <Menu />

    <!-- LOGO MODIFY -->
    <div class="animate-fade-up animate-ease-in-out mx-auto px-4 xl:px-12 flex flex-col items-center text-primary">
      <div class="w-fit mt-5 flex flex-col justify-center items-center">
        <h1 class="heading mt-20 mb-12 font-bold text-3xl lg:text-4xl xl:text-5xl">
          Make It Yours
        </h1>
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-5 md:gap-20">
        <!-- LOGO view-->
        <div class="w-full lg:col-span-3 mx-auto lg:mx-0 mb-8 lg:mb-0">
          <div class="flex justify-center mx-auto p-4 pt-16 rounded-lg bg-white border border-solid shadow-sm" ref="svgContainer"></div>
        </div>
        <!-- LOGO editor-->
        <div class="mx-auto lg:col-span-2 w-full md:order-first">
          <div class="glass mx-auto mb-8 p-4 pt-2 flex flex-col items-start rounded-lg text-white border border-solid shadow-sm">
            <h2 class="font-bold text-xl">Colors</h2>
            <div class="color-list w-full grid grid-cols-6"  v-if="style">
              <div v-for="(color, index) in getSelectedPalette()"
                :key="index" class="color-item rounded-full max-[400px]:w-10 max-[400px]:h-10"
                :style="{ backgroundColor: color }"
                @click="changeLogoColor(color)">
                <div v-if="selectedColor === color" class="selected-overlay"></div>
              </div>
            </div>
          </div>
          <div class="glass mx-auto mb-8 p-4 pt-2 flex flex-col items-start rounded-lg text-white border border-solid shadow-sm">
            <h2 class="font-bold text-xl">Fonts</h2>
            <div class="font-list w-full grid grid-cols-1 xl:grid-cols-2">
              <div v-for="(font, index) in getSelectedFonts()"
                :key="index" class="font-item text-lg cursor-pointer m-0 mt-2 px-2 hover:text-secondary"
                :class="{ active: selectedFont === font }"
                @click="selectFont(font)">
                <div :style="{ fontFamily: font }">{{ businessName }}</div>
                <div v-if="selectedFont === font" class="selected-overlay"></div>
              </div>
            </div>
          </div>
          <div class="mb-2 lg:mb-8 flex flex-col items-start rounded-lg text-white">
            <h2 class="font-bold text-xl">Email</h2>
            <input type="email" v-model="email" @input="validateEmail"
              placeholder="Where should we send your logo?"
              class="w-full rounded-md border-2 font-bold text-primary border border-solid py-2 px-4"/>
          </div>
        </div>     
      </div>

      <!-- Buttons -->
      <div class="w-full p-8 mx-auto flex gap-8 justify-center">
        <button class="w-full lg:w-1/6 text-white disabled:bg-neutral-300 px-4 py-2 border-secondary border-2 rounded-md text-md font-bold"
          @click="previousStep()" :disabled="!businessName">
          ← Previous
        </button>
        <button :class="{
          'w-full lg:w-1/6 px-4 py-2 rounded text-primary text-md font-bold': true,
          'bg-gray-400 cursor-disable': !isValidEmail,
          'bg-secondary hover:bg-secondary-dark': isValidEmail,
          }"
          :disabled="!isValidEmail" @click="downloadPackage">
          Download Your New Logo
        </button>
      </div>
      <div v-if="successMessage" class="text-green-500 font-bold">
        {{ successMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import JSZip from "jszip";
import axios from "axios";
import '../../public/fonts/fonts.css';

export default {
  data() {
    return {
      businessName: "",
      industry: "",
      style: "",
      tone: "",
      images: [],
      joyfulFonts :[
        "ADLaM Display",
        "Atma",
        "Calistoga",
        "Glass Antiqua",
        "Gugi",
        "Kalnia",
        "Acme",
        "Wellfleet",
        "Concert One",
        "Carmen Joyful"
      ],
      professionalFonts: [
        "Arvo",
        "Diphylleia",
        "Girassol",
        "Philosopher",
        "Zen Antique Soft",
        "Prosto One",
        "Dancing Script",
        "Kalam",
        "Laisha",
        "August",
      ],
      boldFonts : ["Bevan", "Bungee", "Caprasimo", "Chonburi", "Tektur", "Tilt Warp", "Paytone One", "Russo One", "Black Ops One", "Titan One"],
      creativeFonts : ["Gaegu", "Happy Monkey", "Lobster", "Stick No Bills", "Architects Daughter", "Zen Tokyo Zoo", "Permanent Marker", "Rubik Doodle Shadow", "Yatra One", "Slackey", "Geo", "Like Snow" ],
      trustworthyFonts : ["Urbanist", "Courier Prime", "Dosis", "Gowun Batang", "Lato", "Space Mono", "Instrument Serif", "Xanh Mono", "Cuprum", "Colombia"],
      selectedFont: null,
      standardPalette: ["#FFFCF2", "#808080", "#33DDFF","#1640D6", "#008000", "#A1E887", "#FE70BD", "#9C27B0", "#BB2525", "#F4CE14", "#FF7900", "#5F331F"], 
      lightPalette: ["#FAFAFA", "#EEEEE4", "#FFF1DC", "#F3FDE8", "#ECF2FF", "#A0E9FF", "#A5F1E9", "#FACBEA", "#FFB9B9", "#CDE990", "#E5D4FF", "#D3F3F1"],
      darkPalette: ["#110C1E", "#321E1E", "#4C0033", "#2E0B3C", "#0A2647", "#451952", "#363062", "#1E5128", "#02383C", "#990000", "#FFC436", "#525252"], 
      warmPalette: ["#3D0C11", "#D80032", "#FA1E0E", "#952323", "#C70039", "#FF6000", "#E3C770", "#F4CE14", "#FFB000", "#D14D72", "#EC8F5E", "#9E6F21"],
      coldPalette: ["#A6F6FF", "#00A9FF", "#6499E9", "#05BFDB", "#64CCC5", "#57C5B6", "#7C93C3", "#687EFF", "#9400FF", "#363062", "#C4DFDF", "#DDF2FD"],
      vintagePalette: ["#A2CDB0", "#C4C1A4", "#FFC6AC", "#FAFDD6", "#ADC4CE", "#776B5D", "#F3EEEA", "#F1C27B", "#537188", "#5C8984", "#BCA37F", "#9E9FA5"], 
      neonPalette: ["#E900FF", "#FF6EFF", "#5800FF", "#0096FF", "#72FFFF", "#16FF00", "#35A29F", "#45FFCA", "#F0FF42", "#FFED00", "#F90716", "#900C3F"],              
      selectedColor: "",
      selectedSvgCode: "",
      svgElement: null,
      email: "",
      isValidEmail: false,
      successMessage: null,
    };
  },
  watch: {
    selectedSvgCode() {
      this.renderSvg();
    },
  },
  created() {
    this.businessName = this.$store.state.businessName;
    this.industry = this.$store.state.industry;
    this.style = this.$store.state.style;
    this.tone = this.$store.state.tone;
    this.images = this.$store.state.images;
    this.selectedImage = this.$store.state.selectedImage;
  },
  methods: {

    async sendEmail() {
      const emailData = {
        to: this.email,
        from: 'contact@logoya.app',
        subject: 'Test Subject',
        text: 'Test Message',
        html: `<strong>Thanks for downloading package and believe in logoya</strong>`,
      };

      try {
        // Call the serverless function directly
        const response = await axios.post('/api/sendmail', emailData);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    },

    async fetchSelectedSvgCode() {
      const selectedImageObject = this.$store.state.images.find(
        (img) => img.src === this.$store.state.selectedImage
      );

      if (selectedImageObject) {
        try {
          const response = await axios.get(selectedImageObject.src);
          this.selectedSvgCode = response.data;
        } catch (error) {
          console.error("Failed to load SVG code:", error);
        }
      }
    },
    renderSvg() {
      const parser = new DOMParser();
      const svgDocument = parser.parseFromString(
      this.selectedSvgCode,
      "image/svg+xml"
      );

      const svgContainer = this.$refs.svgContainer;
      svgContainer.innerHTML = "";

      const svgElement = svgDocument.documentElement;
      // Responsywna szerokość i wysokość Logo na podstawie szerokości ekranu
      const screenWidth = window.innerWidth;
      if (screenWidth < 640) {
        svgElement.setAttribute("width", "30rem");
        svgElement.setAttribute("height", "20rem");
        } else {
        svgElement.setAttribute("width", "40rem");
        svgElement.setAttribute("height", "30rem");
        }
        svgElement.setAttribute("viewBox", "0 0 200 340");
        svgElement.setAttribute("fill", this.selectedColor);

        // warunek tła dla lightPalette
      if (this.style === 'lightColors') {
        svgContainer.classList.remove('bg-white'); // brak tła bg-white
        svgContainer.classList.add('glass'); 
      }

      svgContainer.appendChild(svgElement);

      // Dodanie fontu jako obraz w Canvie wewnatrz SVG
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = 1750;
      canvas.height = 1250;

      // Rysowanie tekstu
      const text = this.businessName;
      const maxFontSize = 380;
      const minFontSize = 140;
      const fontSizeRange = maxFontSize - minFontSize;
      const fontSize = minFontSize + (1 - (text.length - 1) / 14) * fontSizeRange;
      ctx.font = `${fontSize}px ${this.selectedFont}`;
      const x = canvas.width / 2; // Wyśrodkowanie na osi x
      const y = (canvas.height * 0.8); // Ustawienie tekst na 80% wysokości

      ctx.fillStyle = this.selectedColor;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(text, x, y);

      // Pobieranie danych URL 
      const svgTextDataUrl = canvas.toDataURL("image/svg+xml");

      // Dodanie fontu do SVG
      const fontElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "image"
      );
      fontElement.setAttribute("href", svgTextDataUrl);
      fontElement.setAttribute("width", "150%");
      fontElement.setAttribute("height", "100%");
      fontElement.setAttribute("x", "-25%");  //  po zmianach wielkości i rozciągniecie fontu poza szerokość img wypośrodkowuje tekst
      svgElement.appendChild(fontElement);
  
      this.svgElement = svgElement;
    },

    getSelectedPalette() {
      switch (this.style) {
        case 'standardColors':
          return this.standardPalette;
        case 'lightColors':
          return this.lightPalette;
        case 'darkColors':
          return this.darkPalette;
        case 'warmColors':
          return this.warmPalette;
        case 'coldColors':
          return this.coldPalette;
        case 'vintageColors':
          return this.vintagePalette;
        case 'neonColors':
          return this.neonPalette;
        default:
          return [];
      }
    }, 

    changeLogoColor(color) {
      if (this.svgElement) {
        this.svgElement.style.fill = color;
        this.selectedColor = color;
        this.renderSvg();
      }
    },  

    getSelectedFonts() {
      switch (this.tone) {
        case 'Joyful':
          return this.joyfulFonts;
        case 'Professional':
          return this.professionalFonts;
        case 'Bold':
          return this.boldFonts;
        case 'Creative':
          return this.creativeFonts;
        case 'Trustworthy':
          return this.trustworthyFonts;
        default:
          return [];
      }
    }, 

    selectFont(fontName) {
      this.selectedFont = fontName;
      // Wywołaj renderSvg(), aby zaktualizować svgContainer
      this.renderSvg();
    },
   
    validateEmail() {
      // walidacja zapisu email
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      this.isValidEmail = emailPattern.test(this.email);
    },

    previousStep() {
      this.$router.push("/proposals");
    },

    createWebFolder() {
      // Utwórz element canvas i jego style do pobrania samego tekstu
      const canvasText = document.createElement("canvas");
      canvasText.width = 756;
      canvasText.height = 512;
      const ctxText = canvasText.getContext("2d");
      ctxText.font = "60px " + this.selectedFont;
      ctxText.fillStyle = this.selectedColor;
      ctxText.textAlign = "center";
      ctxText.textBaseline = "middle";
      // Narysuj tekst na canvas
      ctxText.fillText(
        this.businessName,
        canvasText.width / 2,   // nie zmieniac tych parametrów bo font idealnie jest na środku dla użytkownika
        canvasText.height / 2
      );
      // Pobierz dane URL z obrazem w formacie PNG i zapisz w folderze web
      const svgTextDataUrl = canvasText.toDataURL("image/svg+xml");
      return svgTextDataUrl.split(",")[1]; // Zwróć tylko dane base64, bez prefiksu
    },

    createBackgroundInFont() {
      const canvasText = document.createElement("canvas");
      canvasText.width = 756;
      canvasText.height = 256;
      const ctxText = canvasText.getContext("2d");

      // Ustaw tło dla fontu
      ctxText.fillStyle = this.selectedColor;
      ctxText.fillRect(0, 0, canvasText.width, canvasText.height);

      ctxText.font = "60px " + this.selectedFont;
      ctxText.fillStyle = "#ffffff"; // Ustaw kolor tekstu na biały
      ctxText.textAlign = "center";
      ctxText.textBaseline = "middle";
      // Narysuj tekst na canvas
      ctxText.fillText(
        this.businessName,
        canvasText.width / 2,
        canvasText.height / 2
      );

      // Pobierz dane URL z obrazem w formacie PNG i zapisz w folderze web
      const svgTextDataUrl = canvasText.toDataURL("image/png");
      return svgTextDataUrl.split(",")[1]; // Zwróć tylko dane base64, bez prefiksu
    },

    createOnlyImage() {
      const parser = new DOMParser();
      const svgDocument = parser.parseFromString(
        this.selectedSvgCode,
        "image/svg+xml"
      );
      const svgElement = svgDocument.documentElement;
      svgElement.setAttribute("width", "800px");
      svgElement.setAttribute("height", "700px");
      svgElement.setAttribute("viewBox", "0 0 200 200");
      svgElement.setAttribute("fill", this.selectedColor);

      // Przykład dla innego rozmiaru np. 3000x3000
      /* const svgElementCopy3000 = svgElement.cloneNode(true);
      svgElementCopy3000.setAttribute("width", "3000px");
      svgElementCopy3000.setAttribute("height", "3000px"); */

      return {
        svgElement: svgElement,
        /* svgElementCopy3000: svgElementCopy3000 */
      };
    },

    createBackgroundInImage() {
      const parser = new DOMParser();
      const svgDocument = parser.parseFromString(
        this.selectedSvgCode,
        "image/svg+xml"
      );
      const svgElement = svgDocument.documentElement;
      svgElement.setAttribute("width", "600px");
      svgElement.setAttribute("height", "600px");
      svgElement.setAttribute("viewBox", "0 0 200 200");
      svgElement.setAttribute("fill", "white"); // kolor grafiki ustawiony dla testów

      // Dodanie tła dla grafiki
      const backgroundRect = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "rect"
      );
      backgroundRect.setAttribute("x", "0");
      backgroundRect.setAttribute("y", "0");
      backgroundRect.setAttribute("width", "100%");
      backgroundRect.setAttribute("height", "100%");
      backgroundRect.setAttribute("fill", this.selectedColor); 
      svgElement.insertBefore(backgroundRect, svgElement.firstChild); // Wstawione tło przed grafiką SVG

      return {
        svgElement: svgElement,
      };
    },

    async downloadPackage() {
      this.$nextTick(() => {
        this.renderSvg();

        // Utwórz nową instancję JSZip i foldery dla plików
        const zip = new JSZip();
        const folder = zip.folder("my logo");
        const pngFolder = folder.folder("png");
        const jpgFolder = folder.folder("jpg");
        const svgFolder = folder.folder("svg");

        // Pobierz całą zawartość SVG
        const svgContainer = this.$refs.svgContainer;
        const svgElement = svgContainer.querySelector("svg");
        const serializer = new XMLSerializer();
        const svgCode = serializer.serializeToString(svgElement);

        // Różne rozmiary SVG
        const sizes = [
          { width: 1024, height: 776 },
          { width: 512, height: 256 },
          { width: 256, height: 128 },
        ];

        // Generuj pliki SVG dla różnych rozmiarów
        sizes.forEach((size) => {
          const svgElement = this.createSvgElement(size.width, size.height);
          const svgCode = this.serializeSvg(svgElement);

          // Zapisz plik SVG w folderze
          svgFolder.file(`my_logo_${size.width}x${size.height}.svg`, svgCode);

          // Utwórz element canvas i pobierz zawartości PNG i JPG
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          const image = new Image();
          image.onload = () => {
            canvas.width = size.width;
            canvas.height = size.height;
            context.drawImage(image, 0, 0);
            const pngDataUrl = canvas.toDataURL("image/png");
            const jpgDataUrl = canvas.toDataURL("image/jpg"); // ważne musi być format .jpg, bo nie ma czarnego tła

            // Zapisz pliki PNG w folderze
            pngFolder.file(
              `my_logo_${size.width}x${size.height}.png`,
              pngDataUrl.substr("data:image/png;base64,".length),
              { base64: true }
            );

            // Zapisz pliki JPG w folderze
            jpgFolder.file(
              `my_logo_${size.width}x${size.height}.jpg`,
              jpgDataUrl.substr("data:image/jpg;base64,".length),
              { base64: true }
            );
          };
          image.src = "data:image/svg+xml;base64," + btoa(svgCode);
        });

        // Dodaj pliki dla folderu "social media"
        const socialMediaFolder = folder.folder("social media");

        // Różne rozmiary FB
        const fbSizes = [
          { name: "cover", width: 812, height: 312 },
          { name: "profile", width: 280, height: 180 },
        ];

        const facebookFolder = socialMediaFolder.folder("Facebook");

        // Generuj pliki PNG dla różnych rozmiarów dla Facebooka
        fbSizes.forEach((size) => {
          const svgElement = this.createSvgElement(size.width, size.height);
          const svgCode = this.serializeSvg(svgElement);

          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          const image = new Image();

          image.onload = () => {
            canvas.width = size.width;
            canvas.height = size.height;
            context.drawImage(image, 0, 0);
            const pngDataUrl = canvas.toDataURL("image/png");

            // Zapisz pliki PNG w folderze
            facebookFolder.file(
              `facebook_${size.name}.png`,
              pngDataUrl.substr("data:image/png;base64,".length),
              { base64: true }
            );
          };
          image.src = "data:image/svg+xml;base64," + btoa(svgCode);
        });

        // Różne rozmiary Linkedin
        const linkedinSizes = [
          { name: "cover", width: 1584, height: 396 },
          { name: "profile", width: 400, height: 300 },
        ];

        const linkedinFolder = socialMediaFolder.folder("LinkedIn");
        // Generuj pliki LinkedIn dla różnych rozmiarów
        linkedinSizes.forEach((size) => {
          const svgElement = this.createSvgElement(size.width, size.height);
          const svgCode = this.serializeSvg(svgElement);
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          const image = new Image();
          image.onload = () => {
            canvas.width = size.width;
            canvas.height = size.height;
            context.drawImage(image, 0, 0);
            const pngDataUrl = canvas.toDataURL("image/png");

            // Zapisz pliki PNG w folderze
            linkedinFolder.file(
              `linkedIn_${size.name}.png`,
              pngDataUrl.substr("data:image/png;base64,".length),
              { base64: true }
            );
          };
          image.src = "data:image/svg+xml;base64," + btoa(svgCode);
        });

        // Różne rozmiary Twitter
        const twitterSizes = [
          { name: "cover", width: 1500, height: 500 },
          { name: "profile", width: 800, height: 700 },
        ];

        const twitterFolder = socialMediaFolder.folder("Twitter");
        // Generuj pliki Twitter dla różnych rozmiarów
        twitterSizes.forEach((size) => {
          const svgElement = this.createSvgElement(size.width, size.height);
          const svgCode = this.serializeSvg(svgElement);
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          const image = new Image();
          image.onload = () => {
            canvas.width = size.width;
            canvas.height = size.height;
            context.drawImage(image, 0, 0);
            const pngDataUrl = canvas.toDataURL("image/png");

            // Zapisz pliki PNG w folderze
            twitterFolder.file(
              `twitter_${size.name}.png`,
              pngDataUrl.substr("data:image/png;base64,".length),
              { base64: true }
            );
          };
          image.src = "data:image/svg+xml;base64," + btoa(svgCode);
        });

        // Różne rozmiary Youtube
        const ytSizes = [
          { name: "cover", width: 2560, height: 1440 },
          { name: "profile", width: 600, height: 500 },
        ];

        const youtubeFolder = socialMediaFolder.folder("Youtube");
        // Generuj pliki yt dla różnych rozmiarów
        ytSizes.forEach((size) => {
          const svgElement = this.createSvgElement(size.width, size.height);
          const svgCode = this.serializeSvg(svgElement);
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          const image = new Image();
          image.onload = () => {
            canvas.width = size.width;
            canvas.height = size.height;
            context.drawImage(image, 0, 0);
            const pngDataUrl = canvas.toDataURL("image/png");

            // Zapisz pliki PNG w folderze
            youtubeFolder.file(
              `youtube_${size.name}.png`,
              pngDataUrl.substr("data:image/png;base64,".length),
              { base64: true }
            );
          };
          image.src = "data:image/svg+xml;base64," + btoa(svgCode);
        });

        // Utwórz element canvas i pobierz zawartość PNG i JPG
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const image = new Image();
        image.onload = () => {
          canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(image, 0, 0);
          /* const pngDataUrl = canvas.toDataURL('image/png');
          const jpgDataUrl = canvas.toDataURL('image/jpg'); */

          // Zapisane główne pliki PNG i JPG bez folderu
          /* folder.file('my_logo.png', pngDataUrl.substr('data:image/png;base64,'.length), { base64: true });
          folder.file('my_logo.jpg', jpgDataUrl.substr('data:image/jpg;base64,'.length), { base64: true }); */

          // Utwórz folder web i dodaj jego zawartość do pobrania
          const webFolder = folder.folder("web");
          const businessNamePng = this.createWebFolder();
          webFolder.file("font.png", businessNamePng, { base64: true });

          const businessNameBackground = this.createBackgroundInFont();
          webFolder.file("background.png", businessNameBackground, {base64: true});

          // Dodaj plik SVG do folderu web (bez fontu)
          const onlyImage = this.createOnlyImage();
          const serializer = new XMLSerializer();
          const codeOnlyImage = serializer.serializeToString(
            onlyImage.svgElement
          );
          webFolder.file("image.svg", codeOnlyImage);

          // Dodaj plik SVG z tłem (bez fontu)
          const backgroundImage = this.createBackgroundInImage();
          const codeBackgroundImage = serializer.serializeToString(
            backgroundImage.svgElement
          );
          webFolder.file("background.svg", codeBackgroundImage);

          /* const codeOnlyImageCopy3000 = serializer.serializeToString(onlyImage.svgElementCopy3000);
          webFolder.file('print.svg', codeOnlyImageCopy2500); */

          // Wygeneruj paczkę ZIP
          zip.generateAsync({ type: "blob" }).then((content) => {
            // Pobierz paczkę ZIP
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(content);
            downloadLink.download = "my logo.zip";
            downloadLink.click();
            this.sendEmail();
          });
        };
        image.src = "data:image/svg+xml;base64," + btoa(svgCode);
        this.successMessage = 'Package downloaded successfully. Enjoy!';
      });
    },

    createSvgElement(width, height) {
      const parser = new DOMParser();
      const svgDocument = parser.parseFromString(
        this.selectedSvgCode,
        "image/svg+xml"
      );

      const svgElement = svgDocument.documentElement;
      svgElement.setAttribute("width", `${width}px`);
      svgElement.setAttribute("height", `${height}px`);
      svgElement.setAttribute("viewBox", "0 0 200 340");
      svgElement.setAttribute("fill", this.selectedColor);

     // Dodanie fontu jako obraz w Canvie wewnatrz SVG
     const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = 1750;
      canvas.height = 1250;

      // Rysowanie tekstu
      const text = this.businessName;
      const maxFontSize = 380;
      const minFontSize = 140;
      const fontSizeRange = maxFontSize - minFontSize;
      const fontSize = minFontSize + (1 - (text.length - 1) / 14) * fontSizeRange;
      ctx.font = `${fontSize}px ${this.selectedFont}`;
      const x = canvas.width / 2; // Wyśrodkowanie na osi x
      const y = (canvas.height * 0.8); // Ustawienie tekst na 80% wysokości

      ctx.fillStyle = this.selectedColor;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(text, x, y);

      // Pobieranie danych URL z obrazem w formacie PNG
      const svgTextDataUrl = canvas.toDataURL("image/svg+xml");

      // Dodanie fontu do SVG
      const fontElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "image"
      );
      fontElement.setAttribute("href", svgTextDataUrl);
      fontElement.setAttribute("width", "150%");
      fontElement.setAttribute("height", "100%");
      fontElement.setAttribute("x", "-25%");  //  po zmianach wielkości i rozciągniecie fontu poza szerokość img wypośrodkowuje tekst
      svgElement.appendChild(fontElement);
      
      return svgElement;
    },

    serializeSvg(svgElement) {
      const serializer = new XMLSerializer();
      return serializer.serializeToString(svgElement);
    },
  },
  mounted() {
    this.fetchSelectedSvgCode();
  },
  computed: {
    selectedImageObject() {
      return this.$store.state.images.find(
        (img) => img.src === this.$store.state.selectedImage
      );
    },
  },
};
</script>
<style lang=""></style>